import { getLast24HoursCheckIns } from "api/check-in";
import { UserListBase } from "components/common/UserListBase";
import { useUsersList } from "hook/useUsersList";

function Last24HoursCheckIns() {
  const userListProps = useUsersList(getLast24HoursCheckIns);
  return <UserListBase {...userListProps} />;
}

export default Last24HoursCheckIns;

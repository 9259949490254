import React from "react";
import { Card, Col } from "react-bootstrap";

export default function Search({
  keyword,
  setKeyword,
  setFilter,
  handleSearch,
}) {
  return (
    <Col md="6">
      <Card className="card-plain table-plain-bg">
        <Card.Header>
          <Card.Title as="h4" className="mb-2">
            Search
          </Card.Title>

          <div>
            <input
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              className="p-2"
              placeholder="Search user by name, email or phone number"
            />

            <select
              style={{ padding: 11 }}
              onChange={(e) => setFilter(e.target.value)}
            >
              {/* <option value="name">Name</option> */}
              <option value="username">Username</option>
              <option value="email">Email</option>
              <option value="phoneNumber">Phone Number</option>
            </select>

            <button className="p-2" onClick={handleSearch}>
              Search
            </button>
          </div>
        </Card.Header>

        <Card.Body className="table-full-width table-responsive px-0"></Card.Body>
      </Card>
    </Col>
  );
}

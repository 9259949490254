import { getLast7DaysNewAccount } from "api/users";
import { UserListBase } from "components/common/UserListBase";
import { useUsersList } from "hook/useUsersList";

function Last7DaysNewAccount() {
  const userListProps = useUsersList(getLast7DaysNewAccount);
  return <UserListBase {...userListProps} />;
}

export default Last7DaysNewAccount;

import moment from "moment";
import { isActiveAccount, isCheckedIn } from "./utils";
import { CSVLink } from "react-csv";
import { Button, Spinner } from "react-bootstrap";

export const prepareUserDataForExport = (users) => {
  return users.map((u) => ({
    Username: u.username || "",
    "Joined At":
      moment(u.createdAt).format("ll") ?? moment(u.joinedAt).format("ll"),
    Name: u.name || "",
    Email: u.email || "",
    "Phone Number": u.phoneNumber || "",
    "Is Account Active": u?.isAccountActive ? "Yes" : "No",
    "Is Checked In": isCheckedIn(u.lastCheckInTime),
    "Account Type": u?.isPurchased ? "Premium" : "Free",
  }));
};

export const CSV_HEADERS = [
  { label: "Username", key: "Username" },
  { label: "Joined At", key: "Joined At" },
  { label: "Name", key: "Name" },
  { label: "Email", key: "Email" },
  { label: "Phone Number", key: "Phone Number" },
  { label: "Is Account Active", key: "Is Account Active" },
  { label: "Is Checked In", key: "Is Checked In" },
  { label: "Account Type", key: "Account Type" },
];

export const ExportUser = ({
  onExport,
  exportData,
  filename = "export",
  loading = false,
}) => {
  return (
    <div className="d-flex gap-2 justify-content-end mb-3">
      {loading ? (
        <Button disabled variant="primary">
          <Spinner size="sm" className="me-1 text-primary" />{" "}
          <span className="text-primary">Preparing Export...</span>
        </Button>
      ) : exportData?.length > 0 ? (
        <CSVLink
          data={exportData}
          headers={CSV_HEADERS}
          filename={`${filename}-${moment().format("YYYY-MM-DD")}.csv`}
          className="btn btn-success"
        >
          Download CSV
        </CSVLink>
      ) : (
        <Button onClick={onExport} variant="primary">
          Download List
        </Button>
      )}
    </div>
  );
};

import { get72HoursNewAccount } from "api/users";
import { UserListBase } from "components/common/UserListBase";
import { useUsersList } from "hook/useUsersList";

function Last72HoursNewAccount() {
  const userListProps = useUsersList(get72HoursNewAccount);
  return <UserListBase {...userListProps} />;
}

export default Last72HoursNewAccount;

import { getLast7DaysNewAccount24HoursCheckIns } from "api/dashboard";
import { UserListBase } from "components/common/UserListBase";
import { useUsersList } from "hook/useUsersList";

function Last7DaysNewAccount24HoursCheckIns() {
  const userListProps = useUsersList(getLast7DaysNewAccount24HoursCheckIns);
  return <UserListBase {...userListProps} />;
}

export default Last7DaysNewAccount24HoursCheckIns;

import { getLast24HoursNewAccount } from "api/users";
import { UserListBase } from "components/common/UserListBase";
import { useUsersList } from "hook/useUsersList";

function Last24HoursNewAccount() {
  const userListProps = useUsersList(getLast24HoursNewAccount);
  return <UserListBase {...userListProps} />;
}

export default Last24HoursNewAccount;

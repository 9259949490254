function isCheckedIn(lastCheckInTime = 0) {
  // Get the last check-in date in UTC
  const lastCheckInDate = new Date(lastCheckInTime);
  const lastCheckedInDay = lastCheckInDate.getUTCDate();

  // Get current date in UTC
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDate();
  const previousDay = currentDay - 1;

  // Return Yes if check-in was on current day or previous day
  return lastCheckedInDay === currentDay || lastCheckedInDay === previousDay
    ? "Yes"
    : "No";
}

function isActiveAccount(lastCheckInTime = Date.now()) {
  const lastCheckedInTime = new Date(lastCheckInTime);

  const currentDate = new Date();

  const timeDifference = currentDate - lastCheckedInTime;

  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  if (daysDifference >= 3) {
    return "No";
  } else {
    return "Yes";
  }
}

const getStatusColor = (status) => {
  switch (status) {
    case "submitted":
      return "blue";
    case "in-progress":
      return "#00ff00";
    case "open":
      return "#0000ff";
    case "on-hold":
      return "#FFD700";
    case "solved":
      return "#00ff00";
    case "closed":
      return "#ff0000";
    default:
      return "#000000";
  }
};

export { isCheckedIn, isActiveAccount, getStatusColor };

import Dashboard from "pages/Dashboard.js";
import TableList from "pages/TableList.js";
import Users from "pages/Users.js";
import PremiumUsers from "pages/PremiumUsers.js";
import FreeUsers from "pages/FreeUsers.js";
import CheckIns from "pages/CheckIns.js";
import Last24HoursMissedCheckInUsers from "pages/Last24HoursMissedCheckInUsers.js";
import Last24HoursCheckIns from "pages/Last24HoursCheckIns";
// import Last72HoursCheckIns from "pages/Last72HoursCheckIns";
import Typography from "pages/Typography.js";
import Configuration from "pages/Configuration.js";
import Last24HoursSms from "pages/Last24HoursSms.js";
import Quotes from "pages/Quotes.js";
import EmergencyAlerts from "pages/EmergencyAlerts.js";
import Notification from "pages/Notification.js";
import FAQs from "pages/FAQs";
import Feedback from "pages/Feedback";
import Admins from "pages/Admins";
import Roles from "pages/Roles";
import Icons from "pages/Icons";
import Last7DaysNewAccount from "pages/Last7DaysNewAccount";
import {
  ADMIN_LAYOUT,
  FAQS,
  FEEDBACK,
  NOTIFICATIONS,
  EMERGENCY_ALERTS,
  LAST_7_DAYS_NEW_ACCOUNT,
  LAST_24_HOURS_SMS,
  LAST_24_HOURS_CHECK_INS,
  LAST_24_HOURS_MISSED_CHECK_INS,
  CHECK_INS,
  FREE_USERS,
  PREMIUM_USERS,
  USERS,
  QUOTES,
  CONFIGURATION,
  ROLES,
  ADMINS,
  DASHBOARD,
} from "utils/routePath";
import { LAST_72_HOURS_CHECK_INS } from "utils/routePath";
import Last72HoursCheckIns from "pages/Last72HoursCheckIns";
import Last24HoursNewAccount from "pages/Last24HoursNewAccount";
import { LAST_24_HOURS_NEW_ACCOUNT } from "utils/routePath";
import { LAST_7_DAYS_NEW_ACCOUNT_24_HOURS_CHECK_INS } from "utils/routePath";
import { LAST_7_DAYS_NEW_ACCOUNT_72_HOURS_CHECK_INS } from "utils/routePath";
import Last7DaysNewAccount24HoursCheckIns from "pages/Last7DaysNewAccount24HoursCheckIns";
import Last7DaysNewAccount72HoursCheckIns from "pages/Last7DaysNewAccount72HoursCheckIns";
import { LAST_72_HOURS_NEW_ACCOUNT } from "utils/routePath";
import Last72HoursNewAccount from "pages/Last72HoursNewAccount";

const dashboardRoutes = [
  {
    path: `/${DASHBOARD}`,
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${ADMINS}`,
    name: "Admins",
    icon: "nc-icon nc-badge",
    component: Admins,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${ROLES}`,
    name: "Roles",
    icon: "nc-icon nc-key-25",
    component: Roles,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${CONFIGURATION}`,
    name: "Configuration",
    icon: "nc-icon nc-atom",
    component: Configuration,
    layout: `/${ADMIN_LAYOUT}`,
  },

  {
    path: `/${QUOTES}`,
    name: "Quotes",
    icon: "nc-icon nc-quote",
    component: Quotes,
    layout: `/${ADMIN_LAYOUT}`,
  },

  {
    path: `/${USERS}`,
    name: "All Users",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${PREMIUM_USERS}`,
    name: "Premium Users",
    icon: "nc-icon nc-circle-09",
    component: PremiumUsers,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${FREE_USERS}`,
    name: "Check-in List",
    icon: "nc-icon nc-circle-09",
    component: FreeUsers,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${CHECK_INS}`,
    name: "Check-in List",
    icon: "nc-icon nc-circle-09",
    component: CheckIns,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_24_HOURS_MISSED_CHECK_INS}`,
    name: "Last 24 Hours Missed Check-in Users",
    icon: "nc-icon nc-circle-09",
    component: Last24HoursMissedCheckInUsers,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_24_HOURS_CHECK_INS}`,
    name: "Last 24 Hours Check-ins",
    icon: "nc-icon nc-circle-09",
    component: Last24HoursCheckIns,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_24_HOURS_SMS}`,
    name: "Last 24 Hours SMS",
    icon: "nc-icon nc-circle-09",
    component: Last24HoursSms,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_72_HOURS_CHECK_INS}`,
    name: "Last 72 Hours Check-ins",
    icon: "nc-icon nc-circle-09",
    component: Last72HoursCheckIns,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_24_HOURS_NEW_ACCOUNT}`,
    name: "Last 24 Hours New Account",
    icon: "nc-icon nc-circle-09",
    component: Last24HoursNewAccount,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_72_HOURS_NEW_ACCOUNT}`,
    name: "Last 72 Hours New Account",
    icon: "nc-icon nc-circle-09",
    component: Last72HoursNewAccount,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },

  {
    path: `/${LAST_7_DAYS_NEW_ACCOUNT}`,
    name: "Last 7 Days New Account",
    icon: "nc-icon nc-circle-09",
    component: Last7DaysNewAccount,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_7_DAYS_NEW_ACCOUNT_24_HOURS_CHECK_INS}`,
    name: "Last 7 Days New Account 24 Hours Check-ins",
    icon: "nc-icon nc-circle-09",
    component: Last7DaysNewAccount24HoursCheckIns,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${LAST_7_DAYS_NEW_ACCOUNT_72_HOURS_CHECK_INS}`,
    name: "Last 7 Days New Account 72 Hours Check-ins",
    icon: "nc-icon nc-circle-09",
    component: Last7DaysNewAccount72HoursCheckIns,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },

  {
    path: `/${EMERGENCY_ALERTS}`,
    name: "Emergency Alerts",
    icon: "nc-icon nc-circle-09",
    component: EmergencyAlerts,
    layout: `/${ADMIN_LAYOUT}`,
    redirect: true,
  },
  {
    path: `/${NOTIFICATIONS}`,
    name: "Notifications",
    icon: "nc-icon nc-notification-70",
    component: Notification,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${FEEDBACK}`,
    name: "Feedback",
    icon: "nc-icon nc-chat-round",
    component: Feedback,
    layout: `/${ADMIN_LAYOUT}`,
  },
  {
    path: `/${FAQS}`,
    name: "FAQs",
    icon: "nc-icon nc-paper-2",
    component: FAQs,
    layout: `/${ADMIN_LAYOUT}`,
  },

  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-circle-09",
  //   component: Icons,
  //   layout: "/admin",
  // },

  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-atom",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
];

export default dashboardRoutes;

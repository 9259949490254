import api from ".";

export const getStats = () => {
  return api.get("/dashboard/stats");
};

export const getUsersSelectOptions = () => {
  return api.get("/dashboard/users-select-options");
};

export const getLast7DaysNewAccount24HoursCheckIns = () => {
  return api.get("/dashboard/last-7days-users-and-last-24-hours-check-ins");
};
export const getLast7DaysNewAccount72HoursCheckIns = () => {
  return api.get("/dashboard/last-7days-users-and-last-72-hours-check-ins");
};

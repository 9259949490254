import api from ".";

export const getUsers = (skip = 0, limit = 20, search, filter, sort) => {
  return api.get("/users", {
    params: {
      skip,
      limit,
      search,
      filter,
      sort,
    },
  });
};

export const getPremiumUsers = (skip = 0, limit = 20, search, filter, sort) => {
  return api.get("/users/premium-users", {
    params: {
      skip,
      limit,
      search,
      filter,
      sort,
    },
  });
};

export const getFreeUsers = (skip = 0, limit = 20, search, filter, sort) => {
  return api.get("/users/free-users", {
    params: {
      skip,
      limit,
      search,
      filter,
      sort,
    },
  });
};

export const updateUser = (user) => {
  return api.put("/users/update-user", user);
};

export const deleteAccount = (userID) => {
  return api.delete(`/users/${userID}`);
};

export const getActivities = (userID, skip, limit) => {
  return api.get(`/users/activities/${userID}`, {
    params: { skip, limit },
  });
};

export const getLast24HoursNewAccount = (
  skip = 0,
  limit = 20,
  search,
  filter,
  sort
) => {
  return api.get("/users/last-24-hours-created-users", {
    params: {
      skip,
      limit,
      search,
      filter,
      sort,
    },
  });
};

export const get72HoursNewAccount = (
  skip = 0,
  limit = 20,
  search,
  filter,
  sort
) => {
  return api.get("/users/last-72-hours-created-users", {
    params: {
      skip,
      limit,
      search,
      filter,
      sort,
    },
  });
};

export const getLast7DaysNewAccount = (
  skip = 0,
  limit = 20,
  search,
  filter,
  sort
) => {
  return api.get("/users/last-7-days-created-users", {
    params: {
      skip,
      limit,
      search,
      filter,
      sort,
    },
  });
};

import React, { useState } from "react";
import { Can } from "components/can";
import Search from "components/search/Search";
import ActivityModal from "components/users/ActivityModal";
import EditModal from "components/users/EditModal";
import UsersTable from "components/users/UsersTable";
import { Alert, Container, Row } from "react-bootstrap";
import { updateUser, deleteAccount } from "api/users";

export const UserListBase = ({
  users,
  total,
  hasMore,
  loading,
  keyword,
  filter,
  setKeyword,
  setFilter,
  handleSearch,
  loadMoreUsers,
  setUsers,
  fetchFunction,
}) => {
  const [edit, setEdit] = useState();
  const [activity, showActivity] = useState(null);

  const handleUpdate = async (user) => {
    try {
      const { data } = await updateUser(user);
      setUsers((prev) =>
        prev.map((u) => {
          if (u._id === data?.user?._id) return data.user;
          return u;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (userID) => {
    try {
      if (
        confirm(
          "The account will be deleted permanently. You can't undo this action."
        )
      ) {
        const { data } = await deleteAccount(userID);
        setUsers((prev) => prev.filter((u) => u._id !== data.deletedUserId));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid>
      <EditModal edit={edit} setEdit={setEdit} handleUpdate={handleUpdate} />
      <ActivityModal user={activity} onClose={() => showActivity(null)} />

      <Can I="read" this="User">
        <Row>
          <Search
            keyword={keyword}
            filter={filter}
            setFilter={setFilter}
            setKeyword={setKeyword}
            handleSearch={handleSearch}
          />

          <UsersTable
            total={total}
            loading={loading}
            users={users}
            hasMore={hasMore}
            setEdit={setEdit}
            loadMore={loadMoreUsers}
            handleDelete={handleDelete}
            showActivity={showActivity}
            fetchFunction={fetchFunction}
            keyword={keyword}
            filter={filter}
          />
        </Row>
      </Can>

      <Can not I="read" this="User">
        <Alert variant="danger">
          You do not have proper permission to access this information.
        </Alert>
      </Can>
    </Container>
  );
};

import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { prepareUserDataForExport } from "utils/exportUser";

export const useExportUser = (fetchFunction, searchParams) => {
  const [exportData, setExportData] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  const handleExport = async () => {
    setLoadingExport(true);
    try {
      const countResponse = await fetchFunction(
        0,
        1,
        searchParams?.keyword,
        searchParams?.filter,
        searchParams?.sorting
      );
      const totalCount = countResponse.data.total;

      const { data } = await fetchFunction(
        0,
        totalCount,
        searchParams?.keyword,
        searchParams?.filter,
        searchParams?.sorting
      );

      if (!data.users || data.users.length === 0) {
        NotificationManager.warning("No data available for export");
        return;
      }

      const formattedData = prepareUserDataForExport(data.users);
      setExportData(formattedData);
      NotificationManager.success(
        `Successfully prepared ${data.users.length} records for export`
      );
    } catch (error) {
      console.error("Export failed:", error);
      NotificationManager.error("Failed to prepare export data");
    } finally {
      setLoadingExport(false);
    }
  };

  return {
    exportData,
    loadingExport,
    handleExport,
  };
};

import { useMutation, useQuery } from "@tanstack/react-query";
import { doFeedbackMessages } from "api/feedback";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";

import ImageGallery from "react-image-gallery";
import { Can } from "components/can";
import { updateFeedbackStatus } from "api/feedback";
import {
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Message from "./Message";
import { getStatusColor } from "utils/utils";
import { getFeedbackEmails } from "api/feedback";
import { setFeedbackEmails } from "api/feedback";

export default function SettingsModal({ hide }) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["emails"],
    queryFn: () => getFeedbackEmails(),
  });

  const mutation = useMutation({
    mutationFn: () => setFeedbackEmails(emails),
    onSuccess(data, variables, context) {
      refetch();
      hide();
    },
  });

  const [emails, setEmails] = useState(data?.data?.emails);

  useEffect(() => {
    setEmails(data?.data?.emails);
  }, [data?.data?.emails]);

  return (
    <>
      <Modal isOpen={true} toggle={() => hide(null)} size="lg" centered>
        <ModalHeader>Settings</ModalHeader>

        <ModalBody>
          <Row>
            <Col>
              <FormGroup className="mb-4 me-4 w-100">
                <Label>
                  New Feedback notification receivers (separate by coma)
                </Label>

                {isLoading ? (
                  <div className="mt-2 text-center">
                    <Spinner size="sm" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <textarea
                    defaultValue={emails}
                    type="text"
                    onChange={(e) => setEmails(e.target.value)}
                    className="p-2 w-100"
                    placeholder="Email address ..."
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-center">
          <Can I="update" this="FeedbackEmails">
            <Button
              variant="dark"
              onClick={() => {
                mutation.mutate();
              }}
              size="sm"
              className="me-2"
            >
              {mutation.isPending ? (
                <Spinner size="sm" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                "Save"
              )}
            </Button>
          </Can>
        </ModalFooter>
      </Modal>
    </>
  );
}

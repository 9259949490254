// Dashboard
export const DASHBOARD = "dashboard";

// User Management
export const USERS = "users";
export const PREMIUM_USERS = "premium-users";
export const FREE_USERS = "free-users";

// Check-ins
export const CHECK_INS = "check-ins";
export const LAST_24_HOURS_CHECK_INS = "last-24-hours-check-ins";
export const LAST_72_HOURS_CHECK_INS = "last-72-hours-check-ins";
export const LAST_24_HOURS_MISSED_CHECK_INS =
  "last-24-hours-missed-check-in-users";

// Downloads and Analytics
export const LAST_24_HOURS_NEW_ACCOUNT = "last-24-hours-new-account";
export const LAST_72_HOURS_NEW_ACCOUNT = "last-72-hours-new-account";
export const LAST_7_DAYS_NEW_ACCOUNT = "last-7-days-new-account";
export const LAST_7_DAYS_NEW_ACCOUNT_24_HOURS_CHECK_INS =
  "last-7-days-new-account-24-hours-check-ins";
export const LAST_7_DAYS_NEW_ACCOUNT_72_HOURS_CHECK_INS =
  "last-7-days-new-account-72-hours-check-ins";

// Communication
export const LAST_24_HOURS_SMS = "last-24-hours-sms";
export const EMERGENCY_ALERTS = "emergency-alerts";
export const NOTIFICATIONS = "notifications";

// Content Management
export const QUOTES = "quotes";
export const FAQS = "faqs";
export const FEEDBACK = "feedbacks";

// Administration
export const ADMINS = "admins";
export const ROLES = "roles";
export const CONFIGURATION = "configuration";

// Layout prefix
export const ADMIN_LAYOUT = "admin";

// Helper function to get full path
export const getFullPath = (path) => `${ADMIN_LAYOUT}${path}`;

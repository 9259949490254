import { useState, useEffect } from "react";

export const useUsersList = (fetchFunction) => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("username");

  const handleSearch = async () => {
    try {
      setLoading(true);
      setHasMore(true);
      setUsers([]);

      const { data } = await fetchFunction(0, 20, keyword, filter);

      const responseUsers = data?.users || data?.data?.users || [];
      const responseTotal = data?.total || data?.data?.total || 0;

      setUsers(responseUsers);
      setTotal(responseTotal);
      setHasMore(responseUsers.length === 20);
    } catch (error) {
      setUsers([]);
      setTotal(0);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreUsers = async (skip, limit = 20) => {
    try {
      setLoading(true);
      const { data } = await fetchFunction(
        skip || users.length,
        limit,
        keyword,
        filter
      );

      const responseUsers = data?.users || data?.data?.users || [];
      const responseTotal = data?.total || data?.data?.total || 0;

      setUsers((prev) => [...prev, ...responseUsers]);
      setTotal(responseTotal);
      setHasMore(responseUsers.length === 20);
    } catch (error) {
      console.log(error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return {
    users,
    total,
    hasMore,
    loading,
    keyword,
    filter,
    setKeyword,
    setFilter,
    handleSearch,
    loadMoreUsers,
    setUsers,
    fetchFunction,
  };
};

import { Can } from "components/can";
import { useExportUser } from "hook/useExportUser";
import moment from "moment";
import React from "react";
import { Button, Col, Pagination, Row, Spinner, Table } from "react-bootstrap";
import { ExportUser } from "utils/exportUser";
import { isCheckedIn } from "utils/utils";
import { isActiveAccount } from "utils/utils";

export default function UsersTable({
  loading = false,
  sorting = "asc",
  setSort,
  users,
  total,
  loadMore,
  hasMore,
  setEdit,
  handleDelete,
  showActivity,
  fetchFunction,
  keyword,
  filter,
}) {
  const totalPages = Math.max(Math.ceil(total / 20), 1);
  const [currentPage, setCurrentPage] = React.useState(1);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    if (loading) return;

    setCurrentPage(page);
    const skip = (page - 1) * 20;
    loadMore(skip, 20);
  };

  const { exportData, loadingExport, handleExport } = useExportUser(
    fetchFunction,
    { keyword, filter, sorting }
  );

  // Keep pagination UI visible as long as we have data
  const showPagination = total > 0;

  return (
    <>
      <ExportUser
        onExport={handleExport}
        exportData={exportData}
        filename={`users-${currentPage}-${moment().format("YYYY-MM-DD")}.csv`}
        loading={loadingExport}
      />
      <Col sm={12}>
        <Table className="table-hover">
          <thead>
            <tr>
              <th className="border-0">Username</th>
              <th className="border-0">Joined At</th>
              {/* <th className="border-0">Name</th> */}
              <th className="border-0">Email</th>
              <th className="border-0">Phone Number</th>
              <th className="border-0">Is Account Active</th>
              <th className="border-0">
                <span>Is Checked In </span>
                {/* <br />
                <i className={`nc-icon nc-stre-down pointer me-2 ${sorting === "asc" ? "text-black" : ""}`} onClick={() => setSort("asc")}></i>
                <i className={`nc-icon nc-stre-up pointer ${sorting === "desc" ? "text-black" : ""}`} onClick={() => setSort("desc")}></i> */}
              </th>
              <th className="border-0">Account Type</th>
              <th className="border-0">Action</th>
            </tr>
          </thead>
          <tbody>
            {users?.length > 0 &&
              users.map((u, idx) => (
                <tr key={idx}>
                  <td>{u.username}</td>
                  <td>{moment(u.createdAt || u.joinedAt).format("ll")}</td>
                  {/* <td>{u.name}</td> */}
                  <td>{u.email}</td>
                  <td>{u.phoneNumber}</td>

                  <td>{u?.isAccountActive ? "Yes" : "No"}</td>

                  <td>{isCheckedIn(u.lastCheckInTime)}</td>

                  <td>{u?.isPurchased ? "Premium" : "Free"}</td>

                  <td>
                    <Can I="update" this="User">
                      <Button
                        size="sm"
                        className="btn-round me-2"
                        onClick={() => setEdit(u)}
                      >
                        Edit
                      </Button>
                    </Can>
                    <Can I="delete" this="User">
                      <Button
                        size="sm"
                        className="btn-round me-2"
                        onClick={() => handleDelete(u._id)}
                        color="danger"
                      >
                        Delete
                      </Button>
                    </Can>
                    <Button
                      size="sm"
                      className="btn-round"
                      onClick={() => showActivity(u._id)}
                      color="danger"
                    >
                      Activity
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Col>

      <Col
        className="d-flex justify-content-center"
        style={{ overflowX: "scroll", width: "100%" }}
      >
        {showPagination && (
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {currentPage > 3 && <Pagination.Ellipsis />}
            {Array.from({ length: Math.min(totalPages, 4) }, (_, i) => {
              const page = currentPage <= 3 ? i + 1 : currentPage - 2 + i;
              return (
                <Pagination.Item
                  key={page}
                  active={page === currentPage}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </Pagination.Item>
              );
            })}
            {currentPage < totalPages - 2 && <Pagination.Ellipsis />}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        )}
      </Col>
    </>
  );
}

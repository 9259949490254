import { getPremiumUsers } from "api/users";
import { UserListBase } from "components/common/UserListBase";
import { useUsersList } from "hook/useUsersList";

function TableList() {
  const userListProps = useUsersList(getPremiumUsers);
  return <UserListBase {...userListProps} />;
}

export default TableList;
